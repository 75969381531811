import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Fragment>
      <div className="mt-5 mb-5">
        <hr />
        <div className="container">
          <div className="text-center">
            <div className="info">
              {/* <span>
                <Link to="/about">ABOUT</Link>
                <span className="dot">•</span>
              </span> */}
              <span>
                <Link to="/tos">Terms of Service</Link>
                <span className="dot">•</span>
              </span>
              <span>
                <Link to="/privacy">Privacy Policy</Link>
                <span className="dot">•</span>
              </span>
              <span>
                <Link to="/cookies">Cookies</Link>
              </span>
            </div>
            <div className="text-muted">
              <div className="my-3 small">
                Disclaimer: All content is provided for fun and entertainment
                purposes only
              </div>
              <div className="small font-weight-bolder">© 2020 FACEAI</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
