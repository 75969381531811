import React, { useState, useEffect, Fragment } from "react";
import { Button, Container, Form, InputGroup, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { toonify } from "../functions";

const Toonify = () => {
  const [selectetdFile, setSelectedFile] = useState([]);
  const [fileBase64String, setFileBase64String] = useState("");
  const [ReCAPTCHA_Value, setReCAPTCHA_Value] = useState("");
  const [load, setLoad] = useState(false);
  // const [realImage, setRealImage] = useState("");
  const [toonedImage, setToonedImage] = useState("");
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    document.title = "Face AI - Toonify";
    setTimeout(() => {
      setLoad(true);
    }, 1500);
  }, []);

  const onFileChange = async (e) => {
    setSelectedFile(e.target.files);
    const base64 = await encodeFileBase64(e.target.files[0]);
    setFileBase64String(base64.split(",")[1]);
  };

  const encodeFileBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleReCAPTCHAChange = (value) => {
    if (value) {
      setReCAPTCHA_Value(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await toonify(ReCAPTCHA_Value, fileBase64String)
      .then((response) => {
        if (response.status === 200) {
          // setRealImage(`data:image/jpeg;base64,${response.data.real}`);
          if (response.data.data) {
            setToonedImage(
              `https://cdn.faceai.live/toon/${response.data.data}.jpg`
            );
            setReCAPTCHA_Value("");
            setSelectedFile([]);
            setFileBase64String("");
          } else {
            alert("Please try again with different Image.");
          }
        } else {
          alert("Please try again with different Image.");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <Container>
      {Loading && (
        <div className="mt-5 mb-5">
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
            &nbsp;&nbsp;&nbsp;Calculating Results...
          </div>
        </div>
      )}
      {!Loading && (
        <Fragment>
          {!toonedImage && (
            <Fragment>
              <div className="mt-5 text-center">
                <h1 className="font-weight-bold">Toonify</h1>
                <p className="text-muted">
                  Upload a photo and see what you'd look like in an animated
                  movie!
                </p>
              </div>
              <div className="mt-5">
                <Form onSubmit={onSubmit}>
                  <Form.Group>
                    <InputGroup>
                      <div className="custom-file">
                        <Form.Control
                          type="file"
                          id="imagePicker"
                          className="custom-file-input"
                          accept="image/*"
                          onChange={onFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="imagePicker"
                        >
                          {selectetdFile[0]
                            ? selectetdFile[0].name
                            : "Choose File"}
                        </label>
                      </div>
                    </InputGroup>
                  </Form.Group>
                  {selectetdFile[0] && (
                    <Form.Group>
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITEKEY}
                        onChange={handleReCAPTCHAChange}
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Button type="submit" disabled={ReCAPTCHA_Value === ""}>
                      Toonify
                    </Button>
                  </Form.Group>
                </Form>
              </div>

              <div className="container">
                <h1 id="faq" className="mt-5">
                  Frequently Asked Questions
                </h1>
                <ul className="mt-5">
                  <li>
                    <h4>How do I get good results?</h4>
                    <p>
                      The algorithm works best with high resolution images
                      without much noise. Looking straight on to the camera also
                      seem to work best. Something like a corporate headshot
                      tends to work well.
                    </p>
                  </li>
                  <li>
                    <h4>My face wasn't found!</h4>
                    <p>
                      We use the open source{" "}
                      <a
                        href="http://dlib.net/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        dlib
                      </a>{" "}
                      face detector to find faces, it's designed to pick up
                      frontal faces but isn't perfect.
                    </p>
                  </li>
                  <li>
                    <h4>How does this work?</h4>
                    <p>
                      This toonification system is made using deep learning.
                      It's based on distillation of a blended StyleGAN models
                      into a pix2pixHD image to image translation network.
                    </p>
                  </li>
                  <li>
                    <h4>What is StyleGan?</h4>
                    <p>
                      StyleGAN is a novel generative adversarial network
                      introduced by Nvidia researchers in December 2018, and
                      made source available in February 2019. StyleGAN depends
                      on Nvidia's CUDA software, GPUs and TensorFlow.
                    </p>
                  </li>
                  <li>
                    <h4>What is Pix2PixHD?</h4>
                    <p>
                      Pix2PixHD is a Pytorch implementation of a deep
                      learning-based method for high-resolution (e.g. 2048x1024)
                      photorealistic image-to-image translation. It can be used
                      for turning semantic label maps into photo-realistic
                      images or synthesizing portraits from face label maps.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="container">
                <h1 id="faq" className="mt-5">
                  Credits
                </h1>
                <ul className="mt-5">
                  <li>
                    <p>
                      Toonify model was highly inspired by the work of{" "}
                      <a
                        href="https://twitter.com/Buntworthy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Justin
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://twitter.com/Norod78"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Doron Adler
                      </a>{" "}
                      on StyleGan2 network blending.
                    </p>
                  </li>
                </ul>
              </div>
            </Fragment>
          )}
          {toonedImage && (
            <Fragment>
              <div className="row mt-5">
                {/* <div className="col-lg-6 col-md-6 col-xs-12">
                  <img className="img-fluid" src={realImage} alt="" />
                </div> */}
                <div className="col-12">
                  <img
                    className="img-fluid mx-auto d-block"
                    src={toonedImage}
                    alt=""
                  />
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Container>
  );
};

export default Toonify;
