import axios from "axios";

export async function toonify(captcha, image) {
  return axios.post(
    "https://toon.faceai.live/",
    {
      captcha: captcha,
      image: image,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
