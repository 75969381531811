import React, { Fragment, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import poster from "../assets/images/LeonardoDiCaprio.jpg";

const Home = () => {
  useEffect(() => {
    document.title = "Face AI - Home";
  }, []);

  return (
    <Fragment>
      <Container>
        <div className="mt-5">
          <Row>
            <Col xl={4} lg={4} md={8} sm={8} xs={12}>
              <Card as={Link} to="/toon">
                <Card.Img variant="top" src={poster} />
                <Card.Body>
                  <Card.Title>Toonify Yourself</Card.Title>
                  <Card.Text className="text-muted">
                    Upload a photo and see what you'd look like in an animated
                    movie!
                  </Card.Text>
                  <Button as={Link} to="/toon" variant="primary">
                    Toonify
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Home;
