import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Toonify from "./pages/Toonify";
import Cookies from "./pages/Cookies";
import Privacy from "./pages/Privacy";
import Tos from "./pages/Tos";
import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";

const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/toon" component={Toonify} />
        <Route path="/cookies" component={Cookies} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/tos" component={Tos} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
